import * as React from 'react'

function SvgItDesignersItalia(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M8.7 20.2v-13h3.6v13.1l-3.6-.1zM23 10.1h-3.3v6.5c0 .2.1.4.3.6s.4.2.8.2h2.1l.2 2.8c-1.2.3-2.1.4-2.8.4-1.6 0-2.7-.3-3.3-1.1-.6-.7-.9-2-.9-3.9v-12h3.6v3.6H23v2.9zm-19.8 1c-.6 0-1.2-.2-1.6-.6-.4-.4-.6-.9-.6-1.5s.2-1.1.6-1.5c.4-.5.9-.7 1.6-.7s1.1.2 1.5.6c.4.5.6 1 .6 1.6s-.2 1.1-.6 1.5c-.4.4-.9.6-1.5.6z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  )
}

export default SvgItDesignersItalia
