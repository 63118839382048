import * as React from 'react'

function SvgItMinimize(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M3 15.5h5.5V21h-1v-4.5H3zM7.5 7.5H3v1h5.5V3h-1zM15.5 21h1v-4.5H21v-1h-5.5zM16.5 7.5V3h-1v5.5H21v-1z" />
      <g>
        <path fill="none" d="M0 0h24v24H0z" />
      </g>
    </svg>
  )
}

export default SvgItMinimize
