import * as React from 'react'

function SvgItZoomOut(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M6.2 9.5h7.5v1H6.2zm14.9 12.4L15.3 16a8.3 8.3 0 01-5.3 2 8 8 0 118-8 8.3 8.3 0 01-2 5.3l5.9 5.8zM17 10a7 7 0 10-7 7 7 7 0 007-7z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  )
}

export default SvgItZoomOut
