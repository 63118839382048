import * as React from 'react'

function SvgItUser(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M12 13a4 4 0 004-4V6a4 4 0 00-8 0v3a4 4 0 004 4zM9 6a3 3 0 016 0v3a3 3 0 01-6 0zm11 16h-1a7 7 0 00-14 0H4a8 8 0 0116 0z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  )
}

export default SvgItUser
