import * as React from 'react'

function SvgItArrowDownTriangle(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M9.2 12h5.6a1 1 0 01.7 1.7L12 17.3l-3.5-3.6a1 1 0 01.7-1.7z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  )
}

export default SvgItArrowDownTriangle
