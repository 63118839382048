import * as React from 'react'

function SvgItWhatsappSquare(props) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M16 8.07a5.34 5.34 0 00-9.11 3.77 5.28 5.28 0 00.82 2.84l.12.2-.53 2 2-.53.2.11c.82.49 1.756.75 2.71.75A5.34 5.34 0 0016 8.07zm-.83 5.83a1.66 1.66 0 01-1.08.76 2.23 2.23 0 01-1-.06c-.23-.08-.53-.18-.91-.34a7.16 7.16 0 01-2.78-2.42 3.13 3.13 0 01-.66-1.66 1.83 1.83 0 01.56-1.34.59.59 0 01.43-.2H10c.1 0 .23 0 .36.27.13.27.45 1.11.49 1.19a.3.3 0 010 .28 1.2 1.2 0 01-.16.27c-.08.1-.17.21-.24.28-.07.07-.17.17-.07.33.239.413.54.787.89 1.11.38.34.816.612 1.29.8.16.08.25.06.34 0 .09-.06.4-.47.51-.63.11-.16.21-.14.36-.08.15.06.94.44 1.1.52.16.08.26.12.3.19a1.36 1.36 0 01-.03.73h.03z" />
      <path d="M21 7.09a8.26 8.26 0 00-.09-1.15 3.49 3.49 0 00-1.85-2.51 3.89 3.89 0 00-1-.32A8.36 8.36 0 0016.91 3H7.09a8.26 8.26 0 00-1.15.09A3.49 3.49 0 003.43 5a3.89 3.89 0 00-.32 1A8.36 8.36 0 003 7.09v9.82c.003.385.033.77.09 1.15A3.49 3.49 0 005 20.57a3.89 3.89 0 001 .32c.384.06.771.094 1.16.1h9.82a8.26 8.26 0 001.15-.09 3.49 3.49 0 002.51-1.85 3.89 3.89 0 00.32-1 8.36 8.36 0 00.1-1.16V7.57c-.06-.1-.06-.31-.06-.48zm-8.8 11.17a6.39 6.39 0 01-3.06-.78l-3.41.89.92-3.32a6.33 6.33 0 01-.86-3.21 6.41 6.41 0 0111-4.53 6.33 6.33 0 011.88 4.54 6.42 6.42 0 01-6.47 6.41z" />
    </svg>
  )
}

export default SvgItWhatsappSquare
