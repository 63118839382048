import * as React from 'react'

function SvgItShare(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M17.5 15a2.5 2.5 0 00-2 1l-7.6-3.4A1.3 1.3 0 008 12a1.3 1.3 0 00-.1-.6L15.5 8a2.5 2.5 0 10-.5-1.5 1.3 1.3 0 00.1.6l-7.6 3.4a2.5 2.5 0 100 3l7.6 3.4a1.3 1.3 0 00-.1.6 2.5 2.5 0 102.5-2.5zm0-10A1.5 1.5 0 0119 6.5a1.5 1.5 0 01-3 0A1.5 1.5 0 0117.5 5zm-12 8.5a1.5 1.5 0 010-3 1.5 1.5 0 010 3zm12 5.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  )
}

export default SvgItShare
