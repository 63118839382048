import * as React from 'react'

function SvgItCopy(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M19.5 4H18v-.6A1.5 1.5 0 0016.5 2h-11A1.5 1.5 0 004 3.4v15.2A1.5 1.5 0 005.5 20H7v.6A1.5 1.5 0 008.5 22h11a1.5 1.5 0 001.5-1.4V5.4A1.5 1.5 0 0019.5 4zM5 18.6V3.4a.5.5 0 01.5-.4h11a.5.5 0 01.5.4v15.2a.5.5 0 01-.5.4h-11a.5.5 0 01-.5-.4zm15 2a.5.5 0 01-.5.4h-11a.5.5 0 01-.5-.4V20h8.5a1.5 1.5 0 001.5-1.4V5h1.5a.5.5 0 01.5.4z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  )
}

export default SvgItCopy
