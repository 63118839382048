import * as React from 'react'

function SvgItMail(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M20.5 5h-17A1.5 1.5 0 002 6.5v11A1.5 1.5 0 003.5 19h17a1.5 1.5 0 001.5-1.5v-11A1.5 1.5 0 0020.5 5zm-.3 1l-7.1 7.2a1.6 1.6 0 01-2.2 0L3.8 6zM3 17.3V6.6L8.3 12zm.7.7L9 12.7l1.2 1.2a2.7 2.7 0 003.6 0l1.2-1.2 5.3 5.3zm12-6L21 6.6v10.7z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  )
}

export default SvgItMail
