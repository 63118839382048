import * as React from 'react'

function SvgItKey(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M18 10a2 2 0 102 2 2 2 0 00-2-2zm0 3a1 1 0 111-1 .9.9 0 01-1 1zm-1-6a5.1 5.1 0 00-4.6 3H3.3l-2 2 3 3H7v-1h.3l1 1H11v-1h1.4a5.1 5.1 0 004.6 3 5 5 0 000-10zm0 9a4.1 4.1 0 01-3.8-2.7V13H10v1H8.7l-1-1H6v1H4.7l-2-2 1-1h9.4v-.3A4.1 4.1 0 0117 8a4 4 0 010 8z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  )
}

export default SvgItKey
