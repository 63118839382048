import * as React from 'react'

function SvgItExternalLink(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M21 3v6h-1V4.7l-7.6 7.7-.8-.8L19.3 4H15V3zm-4 16.5a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5H12V6H4.5A1.5 1.5 0 003 7.5v12A1.5 1.5 0 004.5 21h12a1.5 1.5 0 001.5-1.5V12h-1z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  )
}

export default SvgItExternalLink
