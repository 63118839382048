import * as React from 'react'

function SvgItPlug(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M8.5 9.9l-2.8 2.8-1.1 1.1c-.6.6-.6 1.5 0 2.1L6 17.3l-3.8 3.9.7.7L6.7 18l1.4 1.4c.3.3.7.4 1.1.4.4 0 .8-.2 1.1-.4l1.1-1.1 2.8-2.8-5.7-5.6zm1 8.8c-.2.2-.5.2-.7 0l-3.5-3.5c-.2-.2-.2-.5 0-.7l.4-.4 4.2 4.2-.4.4zm-3.2-5.3l2.1-2.1 4.2 4.2-2.1 2.1-4.2-4.2zm9.6-8.8c-.6-.6-1.5-.6-2.1 0L11.3 7l1.4 1.4-2.1 2.1.7.7 2.1-2.1 1.4 1.4-2.1 2.1.7.7 2.1-2.1 1.5 1.4 2.5-2.5c.6-.6.6-1.5 0-2.1l-3.6-3.4zm2.8 4.9L17 11.3 12.7 7l1.8-1.8c.2-.2.5-.2.7 0l3.5 3.5c.2.3.2.6 0 .8zM8.5 12.7l.7.7-.7.7-.7-.7.7-.7zm2.8 2.8l-.7.7-.7-.7.7-.7.7.7zM18 6.7l-.7-.7 3.9-3.8.7.7L18 6.7z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  )
}

export default SvgItPlug
