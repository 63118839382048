import * as React from 'react'

function SvgItArrowLeftTriangle(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M12 9.2v5.6a1 1 0 01-1.7.7L6.8 12l3.5-3.5a1 1 0 011.7.7z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  )
}

export default SvgItArrowLeftTriangle
