import * as React from 'react'

function SvgItInstagram(props) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 4.62l-.01.08H8.35a5.12 5.12 0 00-1.67.31c-.38.15-.72.382-1 .68-.298.28-.53.62-.68 1a5.12 5.12 0 00-.3 1.68v7.28c.01.57.114 1.134.31 1.67.15.38.382.72.68 1 .28.298.62.53 1 .68.536.196 1.1.3 1.67.31.95.05 1.24.05 3.64.05 2.4 0 2.64-.05 3.64-.05a5.12 5.12 0 001.67-.31A3.08 3.08 0 0019 17.31a5.12 5.12 0 00.3-1.68c.05-.95.05-1.24.05-3.64 0-2.4-.05-2.64-.05-3.64a5.12 5.12 0 00-.31-1.67 2.77 2.77 0 00-.68-1 2.77 2.77 0 00-1-.68 5.12 5.12 0 00-1.67-.38H12zM12 3v.05h3.71a6.9 6.9 0 012.19.42 4.64 4.64 0 012.68 2.63A6.9 6.9 0 0121 8.29c0 .96.05 1.27.05 3.71S21 14.71 21 15.71a6.9 6.9 0 01-.47 2.24 4.64 4.64 0 01-2.63 2.63 6.9 6.9 0 01-2.19.42c-.96.05-1.27.05-3.71.05S9.29 21 8.29 21a6.9 6.9 0 01-2.19-.47 4.64 4.64 0 01-2.63-2.63 6.9 6.9 0 01-.42-2.19V12 8.29a6.9 6.9 0 01.42-2.19A4.64 4.64 0 016.1 3.42 6.9 6.9 0 018.29 3H12zm0 4.38a4.62 4.62 0 110 9.24 4.62 4.62 0 010-9.24zM12 15a3 3 0 100-6 3 3 0 000 6zm4.8-6.72a1.08 1.08 0 110-2.16 1.08 1.08 0 010 2.16z" />
    </svg>
  )
}

export default SvgItInstagram
