import * as React from 'react'

function SvgItRss(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M5.67 15.66a2.67 2.67 0 102.67 2.67 2.68 2.68 0 00-2.67-2.67z" />
      <path d="M3.28 8.88a.28.28 0 00-.28.28v3.39a.29.29 0 00.28.28 7.9 7.9 0 017.89 7.89.29.29 0 00.28.28h3.41a.28.28 0 00.27-.28A11.93 11.93 0 003.28 8.88z" />
      <path d="M21 20.72A17.84 17.84 0 003.28 3a.29.29 0 00-.28.28v3.49a.28.28 0 00.28.23A13.69 13.69 0 0117 20.72a.29.29 0 00.28.28h3.5a.29.29 0 00.29-.28z" />
    </svg>
  )
}

export default SvgItRss
