import * as React from 'react'

function SvgItBehance(props) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M7.83 5.75a8 8 0 011.6.15 3.38 3.38 0 011.24.51c.345.233.62.554.8.93.202.456.298.952.28 1.45a2.68 2.68 0 01-.42 1.54 3.05 3.05 0 01-1.25 1 2.92 2.92 0 011.68 1.15c.393.59.59 1.29.56 2a3.39 3.39 0 01-.32 1.59 3.08 3.08 0 01-1 1.11 4.27 4.27 0 01-1.4.64A6 6 0 018 18H2V5.75h5.83zm-.35 5a2 2 0 001.19-.35c.328-.27.5-.687.46-1.11A1.47 1.47 0 009 8.54a1.16 1.16 0 00-.42-.43A1.8 1.8 0 008 7.9a3.26 3.26 0 00-.7-.06H4.74v2.87l2.74.04zm.15 5.22c.252.003.503-.02.75-.07a2 2 0 00.62-.3 1.39 1.39 0 00.44-.49 1.73 1.73 0 00.16-.8A1.64 1.64 0 009.09 13a2.33 2.33 0 00-1.41-.4H4.74v3.38l2.89-.01zm8.54-.08a2.18 2.18 0 001.58.54 2.24 2.24 0 001.25-.37 1.6 1.6 0 00.65-.79h2.15a4.06 4.06 0 01-1.58 2.29 4.64 4.64 0 01-2.58.69 5.23 5.23 0 01-1.9-.33 4 4 0 01-2.33-2.44 5.25 5.25 0 01-.32-1.89 5.12 5.12 0 01.33-1.86 4.14 4.14 0 01.93-1.49 4.48 4.48 0 011.44-1 4.59 4.59 0 011.85-.36 4.31 4.31 0 012 .44A4 4 0 0121 10.5c.37.505.636 1.08.78 1.69a6.18 6.18 0 01.17 2h-6.38a2.5 2.5 0 00.6 1.7zM19 11.22a1.83 1.83 0 00-1.38-.49 2 2 0 00-1 .2 1.8 1.8 0 00-.62.49 1.62 1.62 0 00-.33.62 2.87 2.87 0 00-.11.59h4a2.4 2.4 0 00-.56-1.41zm-3.93-4.65h4.98v1.21h-4.98V6.57z" />
    </svg>
  )
}

export default SvgItBehance
