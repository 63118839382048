import * as React from 'react'

function SvgItRssSquare(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M17.62 3H6.37A3.37 3.37 0 003 6.38v11.24A3.37 3.37 0 006.37 21h11.25A3.38 3.38 0 0021 17.62V6.38A3.38 3.38 0 0017.62 3zM7.5 18.75a2.25 2.25 0 112.25-2.25 2.25 2.25 0 01-2.25 2.25zm4.5 0A6.75 6.75 0 005.25 12V9.75a9 9 0 019 9zm4.5 0A11.25 11.25 0 005.25 7.5V5.25a13.5 13.5 0 0113.5 13.5z" />
    </svg>
  )
}

export default SvgItRssSquare
