import * as React from 'react'

function SvgItMoreActions(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M6 10a2 2 0 102 2 2 2 0 00-2-2zm0 3a1 1 0 010-2 1 1 0 010 2zm6-3a2 2 0 102 2 2 2 0 00-2-2zm0 3a1 1 0 111-1 .9.9 0 01-1 1zm6-3a2 2 0 102 2 2 2 0 00-2-2zm0 3a1 1 0 111-1 .9.9 0 01-1 1z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  )
}

export default SvgItMoreActions
